define("discourse/plugins/discourse-lazy-videos/discourse/components/lazy-video", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _template, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat-class
      "lazy-video-container"
      (concat @videoAttributes.providerName "-onebox")
      (if this.isLoaded "video-loaded")
    }}
    data-video-id={{@videoAttributes.id}}
    data-video-title={{@videoAttributes.title}}
    data-video-start-time={{@videoAttributes.startTime}}
    data-provider-name={{@videoAttributes.providerName}}
  >
    {{#if this.isLoaded}}
      <LazyIframe
        @providerName={{@videoAttributes.providerName}}
        @title={{@videoAttributes.title}}
        @videoId={{@videoAttributes.id}}
        @startTime={{@videoAttributes.startTime}}
      />
    {{else}}
      <div
        class={{concat-class "video-thumbnail" @videoAttributes.providerName}}
        tabindex="0"
        style={{this.thumbnailStyle}}
        {{on "click" this.loadEmbed}}
        {{on "keypress" this.loadEmbed}}
      >
        <img
          class={{concat @videoAttributes.providerName "-thumbnail"}}
          src={{@videoAttributes.thumbnail}}
          title={{@videoAttributes.title}}
          loading="lazy"
        />
        <div
          class={{concat-class
            "icon"
            (concat @videoAttributes.providerName "-icon")
          }}
        ></div>
      </div>
      <div class="title-container">
        <div class="title-wrapper">
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="title-link"
            href={{@videoAttributes.url}}
            title={{@videoAttributes.title}}
          >
            {{@videoAttributes.title}}
          </a>
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "5+HCJd+d",
    "block": "[[[10,0],[15,0,[28,[37,0],[\"lazy-video-container\",[28,[37,1],[[30,1,[\"providerName\"]],\"-onebox\"],null],[52,[30,0,[\"isLoaded\"]],\"video-loaded\"]],null]],[15,\"data-video-id\",[30,1,[\"id\"]]],[15,\"data-video-title\",[30,1,[\"title\"]]],[15,\"data-video-start-time\",[30,1,[\"startTime\"]]],[15,\"data-provider-name\",[30,1,[\"providerName\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isLoaded\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@providerName\",\"@title\",\"@videoId\",\"@startTime\"],[[30,1,[\"providerName\"]],[30,1,[\"title\"]],[30,1,[\"id\"]],[30,1,[\"startTime\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[16,0,[28,[37,0],[\"video-thumbnail\",[30,1,[\"providerName\"]]],null]],[24,\"tabindex\",\"0\"],[16,5,[30,0,[\"thumbnailStyle\"]]],[4,[38,4],[\"click\",[30,0,[\"loadEmbed\"]]],null],[4,[38,4],[\"keypress\",[30,0,[\"loadEmbed\"]]],null],[12],[1,\"\\n      \"],[10,\"img\"],[15,0,[28,[37,1],[[30,1,[\"providerName\"]],\"-thumbnail\"],null]],[15,\"src\",[30,1,[\"thumbnail\"]]],[15,\"title\",[30,1,[\"title\"]]],[14,\"loading\",\"lazy\"],[12],[13],[1,\"\\n      \"],[10,0],[15,0,[28,[37,0],[\"icon\",[28,[37,1],[[30,1,[\"providerName\"]],\"-icon\"],null]],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"title-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"title-wrapper\"],[12],[1,\"\\n        \"],[10,3],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"title-link\"],[15,6,[30,1,[\"url\"]]],[15,\"title\",[30,1,[\"title\"]]],[12],[1,\"\\n          \"],[1,[30,1,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@videoAttributes\"],false,[\"concat-class\",\"concat\",\"if\",\"lazy-iframe\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-lazy-videos/discourse/components/lazy-video.hbs",
    "isStrictMode": false
  });
  class LazyVideo extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "isLoaded", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoaded = (() => (dt7948.i(this, "isLoaded"), void 0))();
    loadEmbed() {
      if (!this.isLoaded) {
        this.isLoaded = true;
        this.args.onLoadedVideo?.();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadEmbed", [_object.action]))();
    onKeyPress(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.loadEmbed();
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "onKeyPress", [_object.action]))();
    get thumbnailStyle() {
      const color = this.args.videoAttributes.dominantColor;
      if (color?.match(/^[0-9A-Fa-f]+$/)) {
        return (0, _template.htmlSafe)(`background-color: #${color};`);
      }
    }
  }
  _exports.default = LazyVideo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LazyVideo);
});